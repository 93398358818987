import Page from "../../../common/layout/Page";
import FormFilter from "../../../common/components/forms/formFilter/FormFilter";
import Field from "../../../common/components/forms/Field";
import FieldTextBox from "../../../common/components/forms/fields/FieldTextBox";
import FieldDateRangePicker from "../../../common/components/forms/fields/dateRangePicker/FieldDateRangePicker";
import AgGridContainer from "../../../common/layout/AgGridContainer";
import {AgGridReact} from "ag-grid-react";
import {getAgGridDefault, rightAligned} from "../../../common/components/agGrid/agGridUtility";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {currencyFormatter, dateFormatter, enumFormatter} from "../../../common/formatters/useLocaleFormatters";
import CheckGridButtonComponent from "../../../common/components/agGrid/CheckGridButtonComponent";
import {DetailRowIconRender} from "../../../common/components/agGrid/DetailRowIconRender";
import useApi from "../../../api/useApi";
import useGridFilter from "../../../common/hooks/useGridFilter";
import OrdenCompraDetalle from "./OrdenCompraDetalle";
import FieldMultiSelect from "../../../common/components/forms/fields/multiselect/FieldMultiSelect";
import FieldAutoComplete from "../../../common/components/forms/fields/FieldAutocomplete";
import EstatusLayoutRender from "./EstatusLayoutRender";
import {useAuth} from "../../../common/security/AuthProvider";
import _ from "lodash";
import {defaultFilterOrdenesCompra} from "../../Constantes";
import Button from "@mui/material/Button";
import {CheckCircleOutlined} from "@mui/icons-material";
import {Box} from "@mui/material";
import {ViewDeleteIconRenderer} from "../../../common/components/agGrid/ViewDeleteIconRenderer";
import {useMenu} from "../../../common/layout/MenuProvider";
import menuOptions from "../../../common/security/menuOptions";

const OrdenesCompra = ({nuevoTotal}) => {
    const {t} = useTranslation();
    const editable = true// isEditable(menuOptions.usuarios);
    const puedeEliminar = useMenu().hasAccess(menuOptions.eliminaOC);
    const api = useApi('configuracion/ordenesCompra');
    const onDataLoaded = (data) => {
        nuevoTotal(0,_.sumBy(data, 'monto'));
    }
    const {data,onFilter, addEditGridContext, editForm} = useGridFilter({api, onDataLoaded});
    const {esProveedor} = useAuth();


    const onConfirmOc = ()=>{
    }

    const ActualizarTabla = () => {
        addEditGridContext.refresh();
    }

    const onExport=(filtro)=>{
        addEditGridContext.onExport(filtro,'Ordenes de Compra');
    }
const sincronizarOCs = async () => {
    await api.post('sincronizarOC', {}, {showLoading: true, showSuccess: true})
    await addEditGridContext.refresh();
}
    const [columnDefs] = useState([
        {field: 'folioOrdenCompra', headerName: t('OC'), resizable: false,  minWidth: 150},
        {field: 'proveedor', headerName: t('Proveedor'),resizable: false, minWidth: 500},
        {field: 'fechaRegistro', headerName: t('Fecha Solicitado'), valueFormatter: dateFormatter,resizable: false, minWidth: 150 },
        {field: 'fechaEntrega', headerName: t('Fecha Entrega'), valueFormatter: dateFormatter, resizable: false,minWidth: 150 },
        {field: 'monto', headerName: t('Monto'), valueFormatter:currencyFormatter,minWidth: 150 , resizable: false, ...rightAligned},
        {field: 'moneda', headerName: t('Moneda'), valueFormatter: enumFormatter, enum:'moneda', minWidth: 110, maxWidth: 110, resizable: false},
        {
            cellRenderer: EstatusLayoutRender,
            headerName: t('Estatus'),
            cellRendererParams: {editable, 'enumerador' : 'estatusOrdenCompra'},
            headerComponentParams: {editable},
            resizable: false,
            minWidth: 200, maxWidth: 200,
            comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                if (nodeA.data.estatus === nodeB.data.estatus) return 0;
                return (nodeA.data.estatus > nodeB.data.estatus) ? 1 : -1;
            }
        },
        {
            cellRenderer: CheckGridButtonComponent,
            cellRendererParams: {editable},
            headerComponentParams: {editable},
            minWidth: 75, maxWidth:75
        },
        {
            cellRenderer: DetailRowIconRender,
            cellRendererParams: {editable},
            headerComponentParams: {editable},
            minWidth: 75, maxWidth:75

        },
        {
            cellRenderer: ViewDeleteIconRenderer,
            cellRendererParams: {puedeEliminar, ActualizarTabla, 'proceso' : 'oc'},
            headerComponentParams: {puedeEliminar},
            minWidth: 75, maxWidth:75

        }
    ]);


    return(
        <>
            <Box display="flex" justifyContent="flex-end" position={'absolute'} top={18} right={0}>
                <Button sx={{width: 250}} variant="outlined" color={'info'} startIcon={<CheckCircleOutlined/>}
                        onClick={ () => sincronizarOCs() }>
                    {t('Sincronizar OC\'s')}
                </Button>
            </Box>
            <Page>

                <FormFilter defaultValue={defaultFilterOrdenesCompra} onFilter={onFilter} filterOnInit={true} onExport={onExport} sessionSorageKey={'filtroOrdenesCompra'} >

                    <Field label={'OC'} field={'folioOrdenCompra'} render={FieldTextBox} md={1} value={""}/>
                    <Field label={'Proveedor'} field={'proveedorId'} render={FieldAutoComplete} source={'proveedores'} md={2} visible={() => !esProveedor} />
                    <Field label={'Fecha Solicitado'} field={'fechaRegistro'} render={FieldDateRangePicker}  md={3}/>
                    <Field label={'Fecha Entrega'} field={'fechaEntrega'} render={FieldDateRangePicker}  md={3}/>
                    <Field label={'Estatus'} field={'estatusIds'} render={FieldMultiSelect} source={'estatusOrdenCompra'} md={2}/>

                </FormFilter>

                <AgGridContainer className="ag-theme-material" width={'100%'} >
                    <AgGridReact
                        {...getAgGridDefault(false)}
                        rowData={data}
                        context={{...addEditGridContext,onConfirmOc, onExport}}
                        getRowId={(params) => params.data.id}
                        columnDefs={esProveedor ? columnDefs.filter((columna) => columna.field !== 'proveedor') : columnDefs}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector = {[10, 20, 50, 100]}
                    />
                </AgGridContainer>
                <OrdenCompraDetalle editForm={editForm} disabled={!editable}/>
            </Page>
        </>

    )
}

export default OrdenesCompra;
